<template>
  <div>
    <!-- <b-button
      id="toggle-btn"
      class="mb-1"
      variant="primary"
      @click="chooseFile"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Nova Remessa</span>
    </b-button> -->
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row align-h="between">
            <b-col
              cols="1"
              class="mb-2"
            >
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="[10, 15, 20]"
              />
            </b-col>
            <b-col
              md="8"
            >
              <b-form-input
                v-model="filter"
                type="search"
                size="sm"
                placeholder="Pesquisar"
              />
            </b-col>
          </b-row>
          <b-table
            hover
            striped
            bordered
            responsive
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :items="itemsRemessa"
            :fields="fieldsRemessa"
            class="text-center"
            @filtered="onFiltered"
            @row-clicked="rowClick"
          />
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            size="sm"
            align="center"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-form-file
      v-show="false"
      id="fileUpload"
      v-model="file"
      placeholder=""
      drop-placeholder="Arquivo"
    />
  </div>
</template>

<script>
import {
  // BButton,
  BCard,
  BRow,
  BCol,
  BTable,
  VBModal,
  BFormFile,
  BFormSelect,
  BFormInput,
  BPagination,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    // BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormFile,
    BFormSelect,
    BFormInput,
    BPagination,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      fieldsRemessa: [
        { key: 'remessa', sortable: true },
        { key: 'tipoRemessa', label: 'Tipo Remessa', sortable: true },
        { key: 'dataRemessa', label: 'Dt Remessa', sortable: true },
        { key: 'dataImportacao', label: 'Dt Importação', sortable: true },
      ],
      itemsRemessa: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      file: null,
      credor_id: null,
      filter: '',
    }
  },
  watch: {
    async file() {
      if (this.file) {
        // await this.sendAnexo()
        this.file = null
      }
    },
  },
  async created() {
    await axios.get('api/v1/operacoes/remessasCredor/', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      },
    }).then(res => {
      // eslint-disable-next-line array-callback-return
      res.data.dados.remessas.map(remessa => {
        this.itemsRemessa.push({
          id: remessa.id,
          remessa: remessa.numero_remessa,
          tipoRemessa: remessa.tipo,
          dataRemessa: this.dataHora(remessa.data_remessa, true),
          dataImportacao: this.dataHora(remessa.data_entrada, true),
        })
      })
    })
    this.totalRows = this.itemsRemessa.length
  },
  methods: {
    rowClick(item) {
      this.$router.push({ name: 'credor-remessa-view', params: { id: item.id } })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    chooseFile() {
      document.getElementById('fileUpload').click()
    },
  },
}
</script>
